import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import Popover from "@mui/material/Popover";
import React from "react";
import NotificationCard from "../notifications/notification-card";
import { INotification } from "../../types/notification";

interface MailBadgeProps {
	notifications: INotification[];
}

const MailBadge = (props: MailBadgeProps) => {

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const [notifications, setNotifications] = React.useState<INotification[]>(props.notifications);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDismiss = (id: string) => {
		const newNotifications = notifications.filter(n => n.id !== id);
		setNotifications(newNotifications);
	}

	const open = Boolean(anchorEl);
	const id = open ? "mail-popover" : undefined;
	const count = notifications.length;

	return (
		<>
			<Box
				sx={{
					position: "relative",
				}}
				onClick={(e) => handleClick(e)}
			>
				<IconButton size="large" aria-label="show 4 new mails" color="inherit">
					<Badge badgeContent={count} color="error">
						<MailIcon />
					</Badge>
				</IconButton>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<NotificationCard handleDismiss={handleDismiss} notifications={notifications} />
			</Popover>
		</>
	);
};
export default MailBadge;
