import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { INotification } from "../../types/notification";
import NotificationList from "./notification-list";

interface NotificationCardProps {
	notifications: INotification[];
  handleDismiss?: (id: string) => void;
}

const styleObject = {
	width: 450,
}

const NotificationCard = ({ notifications, handleDismiss }: NotificationCardProps) => {
  return (
    <Card sx={styleObject}>
      <CardContent>
        {notifications.length > 0 ? <NotificationList notifications={notifications} handleDismiss={handleDismiss} /> : <Box>No new notifications.</Box>}
      </CardContent>
    </Card>
  );
};
export default NotificationCard;