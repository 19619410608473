import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from "@mui/material/Popover";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const buttonStlye = {
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "darkgrey",
	},
	padding: 1,
};

const MenuBadge = (props: any) => {

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "menu-popover" : undefined;
	
	return (
		<>
			<Box
				aria-describedby={id}
				onClick={e => handleClick(e)}
			>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="open drawer"
					sx={{ mr: 2 }}
				>
					<MenuIcon />
				</IconButton>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<Card>
					<Stack>
						<Typography sx={buttonStlye}>Home</Typography>
						<Typography sx={buttonStlye}>About</Typography>
						<Typography sx={buttonStlye}>Contact</Typography>
					</Stack>
				</Card>
			</Popover>
		</>
	);
};
export default MenuBadge;