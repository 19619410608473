import AccountCircle from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const buttonStlye = {
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: 'darkgrey',
	},
	padding: 1,
};

const AccountBadge = (props: any) => {

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "account-popover" : undefined;


	return (
		<>
			<Box aria-describedby={id} onClick={e => handleClick(e)}>
				<IconButton
					size="large"
					edge="end"
					aria-label="open drawer"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{
					width: '100%',
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Card>
					<Stack>
						<Typography sx={buttonStlye}>Profile<Typography sx={{fontSize: '12px'}}>marcia.columbro@uhhs.com</Typography></Typography>
						<Typography sx={buttonStlye}>Settings</Typography>
						<Typography sx={buttonStlye}>Notifications</Typography>
						<Typography sx={buttonStlye}>Mail</Typography>
						<Typography sx={buttonStlye}>Recent Patients</Typography>
						<Typography sx={buttonStlye}>Upcomming Patients</Typography>
						<Typography sx={buttonStlye}>Manage</Typography>
					</Stack>
				</Card>
			</Popover>
		</>
	);
};
export default AccountBadge;