import Stack from "@mui/material/Stack";
import { INotification } from "../../types/notification";
import NotificationDisplay from "./notification-display";

interface NotificationListProps {
  notifications?: INotification[];
	handleDismiss?: (id: string) => void;
}

const NotificationList = ({ notifications, handleDismiss }: NotificationListProps) => {
  return (
		<>
			<Stack spacing={1}>
				{notifications ? notifications.map((x, i) => {
					return <NotificationDisplay key={i} item={x} handleDismiss={handleDismiss} />;
				}) : null}
			</Stack>
		</>
	);
};
export default NotificationList;
