import { Collapse, IconButton } from "@mui/material";
import { INotification } from "../../types/notification";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from '@mui/icons-material/Mail';
import React from "react";

interface NotificationProps {
	item: INotification;
	handleDismiss?: (id: string) => void;
}

const styleObject = {
	width: '100%',
}

const NotificationDisplay = ({item: x, handleDismiss}: NotificationProps) => {
	
	
	const handleClose = (id: string) => {
		if (handleDismiss) {
			handleDismiss(id);
			console.log('todo: hook in close event');
		}
	};

	return (
			<Alert
				{...(x.dismissable
					? {
							action: (
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => handleClose(x.id)}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							),
					  }
					: {})
				}
				{...(x.isMail
					? {
							icon: <MailIcon />,
					  }
					: {})
				}
				sx={styleObject}
				severity={x.severity}
			>
				{x.text}
			</Alert>
	);
};
export default NotificationDisplay;