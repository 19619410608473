import { faker } from "@faker-js/faker";
import { Severity } from "../../types/enums/severity";
import { INotification } from "../../types/notification";

export const getNotificationMock = (): INotification => {
	return {
		id: faker.datatype.uuid(),
		userId: faker.datatype.uuid(),
		text: faker.lorem.sentence(5),
		severity: faker.helpers.arrayElement(Object.values(Severity)),
		createdAt: faker.date.recent(),
	} as INotification;
};

export const getArrayOfNotificationsMock = (length: number): INotification[] => {
	let notifications: INotification[] = [];
	for(let i = 0; i < length; i++) {
		notifications.push(getNotificationMock());
	};
	return notifications;
};

export const getMailNotificationsMock = (length: number): INotification[] => {
	let notifications: INotification[] = [];
	for (let i = 0; i < length; i++) {
		let temp = getNotificationMock();
		temp.severity = Severity.Info;
		temp.isMail = true;
		notifications.push(temp);
	}
	return notifications;
}

export const getDismissableNotificationsMock = (length: number): INotification[] => {
	let notifications: INotification[] = [];
	for (let i = 0; i < length; i++) {
		let temp = getNotificationMock();
		temp.dismissable = true;
		notifications.push(temp);
	}
	return notifications;
};

export const getDismissableMailNotificationsMock = (length: number): INotification[] => {
	let notifications: INotification[] = [];
	for (let i = 0; i < length; i++) {
		let temp = getNotificationMock();
		temp.dismissable = true;
		temp.isMail = true;
		temp.severity = Severity.Info;
		notifications.push(temp);
	}
	return notifications;
};