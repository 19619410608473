import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import React from "react";
import { INotification } from "../../types/notification";
import NotificationCard from "../notifications/notification-card";

interface NotificationBadgeProps {
	children?: React.ReactNode;
	notifications: INotification[];
};

const NotificationsBadge = (props: NotificationBadgeProps) => {

	const [notifications, setNotifications] = React.useState<INotification[]>(props.notifications);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDismiss = (id: string) => {
		const newNotifications = notifications.filter(n => n.id !== id);
		setNotifications(newNotifications);
	};

	const count = notifications.length;
	const open = Boolean(anchorEl);
	const id = open ? "notifications-popover" : undefined;

	return (
		<>
			<Box
				aria-describedby={id}
				onClick={e => handleClick(e)}
			>
				<IconButton
					size="large"
					aria-label={`show ${count} new notifications`}
					color="inherit"
				>
					<Badge badgeContent={count} color="error">
						<NotificationsIcon />
					</Badge>
				</IconButton>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<NotificationCard
					handleDismiss={handleDismiss}
					notifications={notifications}
				/>
			</Popover>
		</>
	);
};
export default NotificationsBadge;
