import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import NavBar from '../nav-bar/NavBar';

const style = {
	width: '100%',
	height: '100%',
};

const Root = () => {
  return(
		<Box style={style}>
			<NavBar />
			<Container>
				<Outlet />
			</Container>
		</Box>
	);
};
export default Root;